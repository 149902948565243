// Libs
import React from 'react';
import { Link } from 'gatsby';
// Deps
import Layout from '../components/layout/layout';
import SEO from '../components/layout/seo';
// State
import { useStoresStaticQuery } from '../hooks/useStoresStaticQuery';

const StoresPage = () => {
  const stores = useStoresStaticQuery();
  const aliases = {};

  const displayClasses = (node) => {
    const { title, alias } = node;

    if (aliases[alias]) {
      return '';
    }

    aliases[alias] = true;

    return (
      <div key={alias}>
        <Link to={alias}>{title}</Link>
      </div>
    );
  };

  // console.log('list', list);

  return (
    <Layout containered={true}>
      <SEO title="Stores" />
      <h1>Stores</h1>
      {stores.map(displayClasses)}
      <Link to="/">Go back to the homepage</Link>
    </Layout>
  );
};

export default StoresPage;
